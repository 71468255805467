.img__pellet-stove{
	background-image: url("assets/images/text_bloc/pellet-stove.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 180px;
	width: 100%;

	@media screen and (min-width: 1200px){
		height: 500px;
	}
}

.img__pump{
	background-image: url("assets/images/text_bloc/pump.png");
	background-repeat: no-repeat;
	background-size: cover;
	height: 180px;
	width: 100%;

	@media screen and (min-width: 1200px){
		height: 500px;
	}
}

.img__wood-stove{
	background-image: url("assets/images/text_bloc/wood-stove.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 180px;
	width: 100%;

	@media screen and (min-width: 1200px){
		height: 500px;
	}
}

.img__gas-stove{
	background-image: url("assets/images/text_bloc/gas-stove.png");
	background-repeat: no-repeat;
	background-size: cover;
	height: 180px;
	width: 100%;

	@media screen and (min-width: 1200px){
		height: 500px;
	}
}

.img__water-treatment{
	background-image: url("assets/images/text_bloc/water-treatment.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 180px;
	width: 100%;

	@media screen and (min-width: 1200px){
		height: 500px;
	}
}

.img__hydraulic-stove{
	background-image: url("assets/images/text_bloc/hydraulic-stove.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 180px;
	width: 100%;

	@media screen and (min-width: 1200px){
		height: 500px;
	}
}
