.container-bg{
	display: none;

	@media only screen and (min-width: 1200px) {
		display: none;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: -1;
	}
}

.container-bg img{
	display: none;

	@media only screen and (min-width: 1200px) {
		display: block;
		position: absolute;

		&.resize__one {
			margin-left: -210px;
			margin-top: 73%;
			width: 400px;
		}

		&.resize__two {
			left: 100rem;
			top: 130rem;
			width: 400px;
		}

		&.resize__three {
			margin-left: -210px;
			margin-top: 160%;
			width: 400px;
		}

		&.resize__four {
			margin-left: -120px;
			margin-top: 283%;
			width: 425px;
		}
	}
}
