
.hero{
	height: 100vh;
	padding-top: 58px;

	@media only screen and (min-width: 801px) {
		padding-top: 40px;
	}

	@media only screen and (min-width: 1200px) {
		padding-top: 50px;
	}
}

.hero__bg-computer{
	background-image: url("assets/images/hero/hero_computer.png");
	background-size: cover;
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	z-index: -1;
}

.hero__bg-pump{
	background-image: url("assets/images/hero/hero_pump.png");
	background-size: cover;
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	z-index: -1;
}

.hero__bg-fire{
	background-image: url("assets/images/hero/hero_fire.png");
	background-size: cover;
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	z-index: -1;
}

.hero__bg-granules{
	background-image: url("assets/images/hero/hero_granules.png");
	background-size: cover;
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	z-index: -1;
}

.hero__bg-stove{
	background-image: url("assets/images/hero/hero_stove.png");
	background-size: cover;
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	z-index: -1;
}

.hero__bg-water{
	background-image: url("assets/images/hero/hero_water.png");
	background-size: cover;
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	z-index: -1;
}

.hero__wood_promise{
	text-align: center;
}

.hero__wood_title{
	margin-top: 370px;

	color: $blue;
	font-family: $font-title;
	font-size: $title-size;
	text-align: center;

	@media only screen and (min-width: 500px) {
	}

	@media only screen and (min-width: 801px) {
		font-size: 85px;
	}

	@media only screen and (min-width: 1200px) {
		font-size: $hero-wood-title;
	}
}

.hero__wood_subtitle{
	color: $orange;
	font-family: $font-title;
	font-size: $navbar-text;
	text-align: center;

	@media only screen and (min-width: 801px) {
		font-size: 30px;
	}

	@media only screen and (min-width: 1200px) {
		font-size: $hero-wood-subtitle;
		padding-top: 0;
	}
}

.hero__picto-scroll {
	bottom: 25px;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);

	animation: 0.5s linear 1s infinite alternate anime;

	@keyframes anime{
		0%{
			bottom: 10px;
		}

		70%{
			bottom: 15px;
		}

		100%{
			bottom: 20px;
		}
	}
}


.hero__picto-arrow{
	height: 32px;


	@media (min-width: 768px) {
		height: 50px;
	}

}
