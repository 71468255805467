footer{
	width: 100%;

	text-align: center;
}

.footer__title{
	margin: 10px 0;

	color: $blue;
	font-family: $font-title;
	font-size: $footer-title-size;

	& > img {
		width: 42px;
    margin-right: 0.5rem;
	}
}

.footer__menu{
	padding-top: 1rem 2rem;

	color: $blue;
	font-family: $font-primary;
	font-size: 14px;
	line-height: 1.7rem;
	text-align: left;

	@media screen and (min-width: 801px){
		padding-top: 2rem 0;
	}
}

.footer__menu a {
	padding-right: 1rem 2rem;

	color: $blue;
	font-family: $font-primary;
	font-size: 14px;
	line-height: 1.7rem;
	text-align: center;
	text-decoration: none;
}

.footer__text{
	display: block;
	padding-bottom: 3rem;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;

	a {
		text-decoration: none;
	}
}

.footer__i{
	width: 42px;
	margin: 1rem;
}

.footer__copyright{
	color: $blue;
	font-family: $font-primary;
	font-size: $footer-text-size;
	text-align: center;
}

.footer__row{
	display: block;
	padding: 10px;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;

	@media screen and (min-width: 801px){
		display: flex;
	}
}

.footer__flex--primary{
	flex: 1;
	padding: 50px;
	margin-left: auto;
	margin-right: auto;
	max-width: 200px;

	text-align: left;

	@media screen and (min-width: 801px){
		padding: 50px 0;
	}
}

.footer__flex--secondary{
	flex: 1;
	padding: 50px 16px;
	margin-left: auto;
	margin-right: auto;
	max-width: 250px;

	text-align: left;

	@media screen and (min-width: 801px){
		padding: 50px 0;
	}
}

.footer__flex--third{
	flex: 1;
	padding: 50px 31px;
	margin-left: auto;
	margin-right: auto;
	max-width: 280px;

	text-align: left;

	@media screen and (min-width: 801px){
		padding: 50px 0;
	}
}
