@import "neat";

.container {
	@include grid-container;
	margin: 10px auto;
	width: 100%;

	//margin: 0 auto;
	@media screen and (min-width: 1200px) {
		margin: 10px 0;
	}

	&--fluid {
		width: auto;
	}
}

.column--single {
	background: transparent;

	@media screen and (min-width: 1200px) {
		@include grid-column(1);
	}
}

.container__split {
	@media screen and (min-width: 1200px) {
		@include grid-container;
	}

	//margin: 0 auto;
	margin: 50px auto;
	width: 100%;

	@media screen and (min-width: 1200px) {
		margin: 50px 0;
	}
}
