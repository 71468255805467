
.hero__home{
	height: calc( 100vh - 50px );
	padding-top: 58px;
	position: relative;

	@media only screen and (min-width: 801px) {
		padding-top: 40px;
	}

	@media only screen and (min-width: 1200px) {
		padding-top: 50px;
	}
}

.hero__video{
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.hero__video video {
	filter:opacity(60%);
	height: 100%;
	left: 0;
	object-fit: cover;
	pointer-events: none;
	top: 0;
	width: 100%;
	min-height: 100%;
}

.hero__container{
	align-items: center;
  display: flex;
	height: calc(100vh - 98px);
	margin: 48px auto 0;
	min-height: 400px;
	padding: 0 25px;

	text-align: center;

	@media (min-width: 1200px) {
		margin: 48px auto 0;
		max-width: 1200px;
	}

	&.hero__container--wood{
		display: block !important;
		padding: 0;
		top: 0;
	}
}

.hero__home-promise {
	flex: 1;

	text-align: center;

	@media (min-width: 992px) {
		text-align: left;
	}
}

.hero__illustration{
	display: none;

	@media (min-width: 992px) {
		display: flex;
		flex: 1;
	}

	img {
		display: block;
		height: 100%;
		max-width: 100%;
		width: 100%;
	}
}

// .hero__home-promise{
// 	margin-left: 0;
// 	padding: 0 4rem;
//
// 	text-align: center;
//
// 	@media only screen and (min-width: 712px) {
// 		margin: 25rem;
// 		padding: 0 7rem;
// 	}
//
// 	@media only screen and (min-width: 601px) {
// 		margin-left: 0;
// 		padding-left: 50px;
// 		padding-right: 50px;
//
// 		text-align: center;
// 	}
//
// 	@media only screen and (min-width: 801px) {
// 		// margin: 20rem 0;
// 	}
//
// 	@media only screen and (min-width: 992px) {
// 		// margin-left: 4rem;
// 	}
//
// 	@media only screen and (min-width: 1200px) {
// 		// flex: 1;
// 		// float: left;
// 		// margin: 17rem 1rem 0 17rem;
// 	}
//

// }

.hero__home-title {
	background-color: $orange;
	display: inline-block;
	padding: $default-size/4 $default-size/2;
	margin-bottom: 10px;

	color: $white;
	font-family: $font-title;
	font-size: 42px;
	text-align: left;

	@media (min-width: 768px) {
		font-size: 56px;
	}
}

.hero__first-title {
	margin-bottom: $default-size/2;

	color: $blue;
	font-family: $font-title;
	font-size: 32px;
	font-weight: 700;

	@media (min-width: 768px) {
		margin-bottom: $default-size*2.5;

		font-size: 42px;
	}
}

.hero__home-subtitle {
	margin-bottom: 25px;

	color: #d6580a;
	font-family: $font-title;
	font-size: 32px;
	font-weight: 700;

	@media (min-width: 768px) {
		font-size: 48px;

		margin-bottom: $default-size*2.5;
	}
}

.hero__home-button {
	@media (min-width: 992px) {
		text-align: left;
	}

	.button.button--hero {
		font-size: 24px;

		@media (min-width: 768px) {
			font-size: 32px;
		}
	}
}

@media screen and (min-width: 1200px) {
	.hero__container{
		// @include grid-container;

		// height: 100vh;
		// flex: 2;
		// max-width: 1200px;
		// margin-left: auto;
		// margin-right: auto;
		// padding: 50px;
	}
}
