.data--wood{
	height: 100%;
	justify-content: center;
	width: 100%;

	text-align: center;

	h2{
		color: $orange;
		font-size: $navbar-text;
		justify-content: center;
		line-height: 50px;
		margin-top: 50px;
		text-align: center;

		@media only screen and (min-width: 660px) {
			font-size: $data-wood-subtitle;
			line-height: 100px;
			margin-top: 0;
		}
	}

	h1{
		color: $blue;
		font-family: $font-title;
		font-size: $font-character;
		line-height: 30px;
		text-align: center;

		@media only screen and (min-width: 660px) {
			font-size: $data-wood-title;
			line-height: 90px;
		}
	}
}

.data__row--wood{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	padding: 50px 10px;
}

.data__flex--primary{
	flex: 1;
	padding: 40px;
}

.data__flex--secondary{
	flex: 1;
	padding: 40px;
}

.data__title--wood{
	margin: 15px;

	color: $orange;
	font-family: $font-title;
	font-size: $title-split-size;
	justify-content: center;
	line-height: 60px;
	text-align: center;
}

.data__text{
	margin: 15px;

	color: $blue;
	font-family: $font-primary;
	font-size: $default-size;
	font-weight: 500;
	text-align: left;

	&--center {
		text-align: center;
	}

	&--right {
		text-align: right;
	}
}

.data__button {
	margin-top: 2rem;
}
