.contact{
	background-color: $blue;
	display: block;
	justify-content: center;
	padding: 60px 0;
	width: 100%;

	text-align: center;
}

.contact__title{
	margin: 10px 0 20px 0;

	color: $white;
	font-family: $font-title;
	font-size: $title-size;

	@media screen and (max-width: 600px){
		font-size: $responsive-contact;
	}
}

.contact__subtitle{
	color: $orange;
	font-family: $font-primary;
	font-size: $global-text;
	font-weight: 400;
}
