.orange_bar{
	background-color: $orange;
	height: 60px;
	width: 100%;
}

.blue_bar{
	background-color: $blue;
	height: 60px;
	width: 100%;
}