//
// Vars

// Color
$black: #000000;
$black-grey: #343636;
$blue: #2d386f;
$dark-orange: #A33E00;
$none: transparent;
$orange: #d6580a;
$white: #ffffff;
$white-transparent: RGBa(255,255,255,0.8);

// Font
$font-primary: 'Poppins', sans-serif;
$font-title: 'Amatic SC', cursive;

// Size
$default-size: 16px;
$footer-text-size: 12px;
$title-split-size: 40px;
$font-character: 55px;
$footer-title-size: 35px;
$global-text: 23px;
$navbar-text: 20px;
$responsive-contact: 50px;
$subtitle-size: 32px;
$title-size: 70px;
$topnav-text: 22px;
$split-title-size: 60px;
$data-wood-subtitle: 25px;
$data-wood-title: 80px;
$hero-wood-title: 100px;
$hero-wood-subtitle: 45px;


// Grid system config (from bourbon-neat)
$neat-grid: (
	columns: 2,
	gutter: 60px,
);
