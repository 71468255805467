//
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');


body {
	overflow-x: hidden;

	font-family: $font_primary;
	font-size: $default-size;
}

.title{
	margin: 10px 0 15px 0;

	color: $blue;
	font-family: $font-title;
	font-size: $title-split-size;
	line-height: 55px;
	text-align: center;

	@media only screen and (min-width: 801px) {
		text-align: left;
	}
}

.link__line img{
	align: center;
}
