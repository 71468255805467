
header{
	background: $none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2;
	width: 100%;

	@media only screen and (max-width: 800px) {
		padding: 0;
		position: absolute;
	}
}

.navbar{
	display: flex;
	justify-content: space-between;

	align-items: center;

	&--blue{
		background-color: $blue;

		ul li a{
			color: $white;
			vertical-align: middle;
		}

		.button__phone {
			background-color: $orange;
			border: none;
			border-radius: 5px;
			height: 30px;
			margin: 10px;
			transition: ease 0.5s;

			a{
				color: $white;
				font-family: $font-primary;
				font-size: $default-size;
				font-weight: 400;
				text-decoration: none;
			}

			&:hover{
				background-color: $dark-orange;
			}
		}

		@media only screen and (max-width: 800px) {
			height: 58px;
			width: 100vw;

			ul li a{
				color: $white;

				position: relative;
			}
		}
	}

	&--white{
		background-color: $white-transparent;
		height: 80px;
		width: 100%;

		ul li a{
			display: inline-block;
			position: relative;

			color: $blue;
			font-weight: 700;
		}

		@media only screen and (min-width: 801px) {
			height: auto;
		}

		@media only screen and (max-width: 800px) {
			height: 80px;
			width: 100vw;

			ul li a{
				position: relative;

				color: $white;
				font-size: 20px;
				font-weight: 700;
				text-align: left;
			}
		}
	}
}

// HOVER EFFECT IN NAVBAR LINKS
.link-hover{
	content: '';
	display:inline-block;
	width: 0;
	height: 2px;
	background: $white;
	transition: width .3s;

	&:hover{
		width: 100%;
		transition: width .3s;
	}
}

// NAVBAR
.menu{
	display: flex;
	flex-direction: row;
	height: 100%;

	&--orange{
		background-color: $orange;
		height: 50px;
		width: 200px;
		// margin-right: 120px;
		transition: ease 0.5s;

		&:hover{
			background-color: $dark-orange;
		}

		@media only screen and (min-width: 801px) {
			margin-right: 0;
		}

		@media only screen and (min-width: 992px) {
			// margin-right: 120px;
		}

		@media only screen and (max-width: 800px) {
			li{
				background-color: $orange;
				height: 58px;
				width: 76px;
			}
		}

		.menu_orange--text{
			margin-left: 25px;

			@media only screen and (min-width: 801px) {
				margin-left: 35px;
				padding-top: 4px;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		background: #343636;
		display: contents;
		flex-flow: column;
		height: 100vh;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		transform: scaleY(0);
		transform-origin: top center;
		transition: 200ms transform cubic-bezier(0.36, 0.4, 0.42, 1.48) 100ms,100ms opacity ease-in-out;
		top: 4.55em;
	}
}

.menu li {
	list-style: none;
}

.menu li a {
	display: block;
	padding: 1em 1.5em;

	color: $white;
	font-size: 1rem;
	text-decoration: none;

	@media only screen and (min-width: 801px) {
		display: block;
		padding: 1rem;

		color: $white;
		font-size: 1rem;
		text-decoration: none;
	}

	@media only screen and (max-width: 992px) {
		font-size: 12px;
	}
}

@media only screen and (max-width: 800px) {
	.display_none{
		display: none;
	}

	.display_flex{
		display: flex;
	}
}


/* submenu */
.has-dropdown {
	position: relative;
}

.submenu {
	background-color: $black-grey;
	margin: 0;
	left: 24px;
	opacity: 0;
	position: absolute;
	top: 0;
	transform: scaleY(0);
	transform-origin: top center;

	white-space: nowrap;
	text-align: left;

	/* hide submenus */
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top center;

	@media (min-width: 992px) {
		background-color: $white-transparent;
		left: 0;
		top: 48px;
	}
}

.submenu > li > a {
	padding: 0.8em 1.5em;
}
.submenu .submenu {
	left: -100%;
	top: 0;
}
.submenu .submenu .submenu {
	left: -100%;
	top: 0;
}

/* Arrows */

.arrow {
	border-bottom: 0.15em solid currentColor;
	border-left: 0.15em solid currentColor;
	display: inline-block;
	height: 0.5em;
	margin-top: -0.25em;
	transform: rotate(-45deg);
	transition: transform 100ms ease-in-out;
	width: 0.5em;

	vertical-align: middle;
}

/* Reveal*/
.menu > li:hover > a + .submenu,
.submenu > li:hover > a + .submenu {
	opacity: 1;
	transform: scaleY(1);
}

/* ANIMATE aRROWS */
.menu > li:hover > a > .arrow,
.submenu > li:hover > a > .arrow {
	transform: rotate(225deg);
}

@media only screen and (max-width: 1200px) {
	.submenu .submenu .submenu {
		left: -100%;
		top: 0.5em;
	}

	.submenu {
		min-width: 16em;
	}
}


@media only screen and (max-width: 800px) {
	.menu > li > a {
		color: $black;
		font-size: 1rem;
	}

	.submenu > li > a {
		font-size: 17px !important;
	}

	.submenu .submenu {
		left: 0;
		top: 0;
	}

	.submenu .submenu .submenu {
		left: 0;
		top: 0;
	}

	.menu > li:hover > a + .submenu,
	.submenu > li:hover > a + .submenu {
		position: relative;
	}

	.hamburger {
		width: 37px;
		height: 5px;
		display: block;
		margin-right: 26px;
		background: $blue;
		position: relative;
		cursor: pointer;
		transition: 0.2s transform ease-in-out;
	}

	.hamburger::after,
	.hamburger::before {
		content: "";
		position: absolute;
		left: 0;
		background: inherit;
		width: inherit;
		height: inherit;

		transition: 0.2s transform ease-in-out;
	}

	.hamburger::after {
		top: 0.65em;
	}

	.hamburger::before {
		bottom: 0.65em;
	}

	.close::after,
	.close::before {
		top: 0;
		transition: 0.2s transform ease-in-out;
	}

	.close::before {
		display: none;
	}

	.close {
		transform: rotate(45deg);
		transition: 0.2s transform ease-in-out;
	}

	.close::after {
		transform: rotate(-90deg);
	}

	/* reveal menu */
	input[type="checkbox"]:checked + .menu {
		margin-top: 65px;
		opacity: 1;
		padding: 15px 10px;
		position: absolute;
		transform: scaleY(1);
	}
}

.phone_icon {
	margin-right: 6px;
	width: 14px;

	vertical-align: baseline;
}

.mail_icon {
	margin-right: 6px;
	width: 20px;

	vertical-align: middle;
}

.clock_icon {
	margin-right: 5px;
	width: 18px;

	vertical-align: bottom;
}

.help_icon {
	width: 20px;
	position: absolute;

	vertical-align: baseline;

	@media only screen and (min-width: 801px) {
		padding-left: 10px;

		vertical-align: sub
	}

	@media only screen and (max-width: 800px) {
		margin: 1px;
		position: absolute;
		width: 26px;

		vertical-align: sub;
	}
}

.logo_ahc img{
	display: none;

	@media only screen and (max-width: 800px) {
		display: initial;
		width: 70px;
	}
}
