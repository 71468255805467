.trace-container{
	text-align: center;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.trace{
	margin: 30px;
	width: 275px;

	@media only screen and (min-width: 801px) {
		width: 390px;
	}

	@media only screen and (min-width: 1200px) {
		margin: 0 25% 30px 25%;
		width: 490px;
	}
}

.trace-4{
	margin: 0;
	width: 190px;

	@media only screen and (min-width: 801px) {
		margin: 10px 170px 10px 10px;
		width: 320px;
	}

	@media only screen and (min-width: 1200px) {
		margin: 5px 215px 20px 10px;
		width: 315px;
	}
}
