//

.gallery__wood{
	background-color: $none;
	justify-content: center;
	padding: 70px 0 50px 0;
	width: 100%;

	text-align: center;
}

.gallery__row--wood{
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	padding: 10px 13px;

	@media screen and (min-width: 440px){
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		padding: 10px 0.8px;
	}
}

.gallery__flex--primary--wood{
	padding: 0;

	@media screen and (min-width: 440px){
		display: flex;
		flex-direction: column;
		padding: 5px 10px 5px;
	}
}

.gallery__flex--secondary--wood{
	padding: 0;

	@media screen and (min-width: 440px){
		display: flex;
		flex-direction: column;
		padding: 5px 10px 5px;
	}
}

.gallery__flex--third--wood{
	padding: 0;

	@media screen and (min-width: 440px){
		display: flex;
		flex-direction: column;
		padding: 5px 10px 5px;
	}
}
