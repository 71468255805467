.certif{
	background-color: $none;
	display: block;
	padding: 20px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;

	text-align: center;

	@media screen and (min-width: 850px){
		padding: 60px;
	}
}

.certif--img img{
	opacity: 0.5;

	@media screen and (min-width: 1200px){
		width: 165px;
	}
}
