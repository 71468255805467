.font_color--orange{
    color: $orange;
    font-weight: 600;
    width: auto;
    position: relative;
}

.font_color--blue{
    color: $blue;
    font-weight: 600;
    width: auto;
    position: relative;
}

.font_color--black{
    color: $black;
    font-weight: 600;
    width: auto;
    position: relative;
}