//

.gallery{
	background-color: $blue;
	justify-content: center;
	padding: 70px 0 50px 0;
	width: 100%;

	text-align: center;
}

.gallery__row{
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	padding: 10px 0;

	@media screen and (min-width: 440px){
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
		padding: 10px 0.8px;
	}
}

.gallery__flex {
	padding: 0;

	@media screen and (min-width: 440px){
		display: flex;
		flex-direction: column;
		padding: 5px 25px 20px;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	// push effect
	@-webkit-keyframes push-on-hover {
	  50% {
	    -webkit-transform: scale(0.92);
	    transform: scale(0.92);
	  }
	  100% {
	    -webkit-transform: scale(1);
	    transform: scale(1);
	  }
	}
	@keyframes push-on-hover {
	  50% {
	    -webkit-transform: scale(0.92);
	    transform: scale(0.92);
	  }
	  100% {
	    -webkit-transform: scale(1);
	    transform: scale(1);
	  }
	}

	&:hover {
		@media (min-width: 1200px) {
			-webkit-animation-name: push-on-hover;
		  animation-name: push-on-hover;
		  -webkit-animation-duration: 0.3s;
		  animation-duration: 0.3s;
		  -webkit-animation-timing-function: linear;
		  animation-timing-function: linear;
		  -webkit-animation-iteration-count: 1;
		  animation-iteration-count: 1;
		}
	}
}

.gallery__title{
	margin: 15px;

	color: $orange;
	font-family: $font-title;
	font-size: $subtitle-size;
	text-align: center;
}

.gallery__text{
	margin: 15px;

	color: $white;
	font-family: $font-primary;
	font-size: $default-size;
	text-align: center;
}
