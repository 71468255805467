.split {
	background: transparent;
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;

	text-align: center;

	@media only screen and (min-width: 801px) {
		display: flex;
		align-items: center;
	}
}

.split__image{
	background-image: url("assets/images/split/split-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	height: 100%;
	overflow: hidden;
}

.split__text {
	flex: 1;
	margin: 0;
	order: 0;

	line-height: 25px;
	text-align: justify;

	.split--right & {
		order: 1;

		line-height: 25px;
		text-align: justify;
	}
}

.split__img {
	flex: 1;
	margin: 0px 16px;
	order: 1;

	.split--right & {
	order: 0;
	}
}

.split__subtitle{
	color: $orange;
	text-align: center;

	&.split__subtitle--character{
		margin: 36px 0;

		font-size: 24px;
		text-align: center;
    text-transform: uppercase;
	}

	@media only screen and (min-width: 801px) {
		text-align: left;
	}
}

.split__title{
	margin: 10px 0 50px 0;

	font-size: $responsive-contact;
	text-align: center;

	&.split__title--name{
		margin: 10px 0;

		color: $orange;
		font-family: $font-title;
		font-size: $font-character;
	}
}

.split__text_p{
	margin: 45px 40px;

	@media (min-width: 768px) {
		flex: 1;
	}
}

.split__resize img{
	display: block;
	max-width: 100%;
	height: auto;
}

.character__pic img{
	width: 282px;

	@media only screen and (min-width: 801px) {
		width: 500px;
	}
}

.split__character{
	margin: 45px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;

	text-align: center;

	@media only screen and (min-width: 801px) {
		margin: 0 90px 0px 90px;
		margin-left: auto;
		margin-right: auto;
		max-width: 1200px;
	}
}

.split__title--bio{
	line-height: 30px;
	margin: 40px;

	color: $black;
	font-family: $font-primary;
	font-size: $default-size;
	font-weight: 400;
	text-align: justify;
}

.font_small--size{
	font-size: 10px;
}

.split_text__desktop{
	margin: 45px 40px 45px 40px;
}

.split_text__camping{
	margin: 45px 40px 45px 40px;
}

.split_text__tablet{
	margin: 45px 40px 45px 40px;
}

.split_text__thumb{
	margin: 130px 40px 30px 40px;
}
