.data--contact{
	background-attachment: fixed;
	background-image: url("assets/images/split/split-bg.png");
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	justify-content: center;
	width: 100%;

	text-align: center;
}

.data__number--contact{
	margin: 15px;
	
	color: $orange;
	font-family: $font-title;
	font-size: $hero-wood-subtitle;
	text-align: center;
}

.data__text--contact{
	margin: 15px;
	
	color: $blue;
	font-family: $font-primary;
	font-size: $default-size;
	font-weight: 500;
	text-align: center;
}