.data{
	background-attachment: fixed;
	background-image: url("assets/images/data/data-bg.png");
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	justify-content: center;
	width: 100%;

	text-align: center;
}

.data__row{
	display: block;
	padding: 50px 10px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;

	@media screen and (min-width: 993px){
		display: flex;
	}
}

.data__flex--primary{
	flex: 1;
	padding: 40px;
}

.data__flex--secondary{
	flex: 1;
	padding: 40px;
}

.data__flex--third{
	flex: 1;
	padding: 40px;
}

.data__flex--four{
	flex: 1;
	padding: 40px;
}

.data__number{
	margin: 15px;

	color: $orange;
	font-family: $font-title;
	font-size: $title-size;
	text-align: center;
}

.data__text{
	margin: 15px;

	color: $blue;
	font-family: $font-primary;
	font-size: 20px;
	font-weight: 500;
	text-align: center;

	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
	}
}
