.advantage__wood--bg{
	background-image: url("assets/images/split/split-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
}

.advantages__wood{
	// height: -webkit-fill-available;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	padding: 35px;

	justify-content: center;
	text-align: center;

	@media only screen and (min-width: 660px) {
		padding: 70px 130px;
	}

	@media only screen and (min-width: 1000px) {
		padding: 130px;
	}

	&.advantages__wood--nobg{
		background-image: none;
	}

	h2{
		justify-content: center;
		margin-top: 50px;

		color: $orange;
		font-size: $navbar-text;
		line-height: 30px;
		text-align: center;

		@media only screen and (min-width: 660px) {
			margin-top: 0;

			font-size: $data-wood-subtitle;

		}
	}

	h1, h3{
		margin: 60px 0;

		color: $blue;
		font-family: $font-title;
		font-size: $font-character;
		text-align: center;

		@media only screen and (min-width: 660px) {
			font-size: $data-wood-title;
		}
	}

	p{
		color: $black;
		font-family: $font-primary;
		font-size: $default-size;
		line-height: 20px;
		text-align: center;
	}
}

.advantages__wood .terms p {
	text-align: left;
}
