.button{
	display: inline-block;
	border: none;
	border-radius: 5px;
	margin: 0.625rem;
	padding: 0.625rem;

	font-family: $font-title;
	font-size: $global-text;
	text-align: center;
	text-decoration: none;

	@media only screen and (min-width: 801px) {
		margin: 0px;

		font-size: 19px;
	}

	&.button--hero{
		background-color: $white;
		color: $blue;

		@media only screen and (min-width: 1100px) {
			font-size: $topnav-text;
		}
	}

	&.button--primary{
		background-color: $orange;
		color: $white;
	}

	&.button--secondary{
		background-color: $white;
		color: $orange;
	}

	&.button--overload{
		border-radius: 3px !important;
		font-size: $subtitle-size;
		width: 180px !important;

		@media screen and (max-width: 600px){
			width: 110px !important;

        	font-size: $navbar-text;
		}
	}
}

.button__icon{
	padding: 0 5px 0 10px !important;
	width: 70px !important;

	vertical-align: middle;

	@media only screen and (min-width: 801px) {
		width: 51px !important;
	}
}
