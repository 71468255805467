.job{
	width: 100%;

	text-align: center;
}

.job__title{
	padding: 13px;

	color: $blue;
	font-family: $font-title;
	font-size: $title-size;
	text-align: center;
}

.job__text{
	padding: 30px;

	color: $black;
	font-family: $font-primary;
	font-size: $default-size;
	line-height: 30px;
	text-align: center;
}

.job__row{
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	padding: 20px 0;

	@media screen and (min-width: 801px){
		display: flex;
	}
}

.job__flex--primary{
	flex: 1;
}

.job__flex--secondary{
	flex: 1;

	@media screen and (min-width: 801px){
		padding: 0 5px;
	}
}

.job__flex--third{
	flex: 1;
	
	@media screen and (min-width: 801px){
		padding: 0 5px;
	}
}