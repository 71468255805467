//
// MARGINS / PADDINGS
// 0 : 16px / 2
// 1 : 16px
// 2 : 16px * 1.5
// 3 : 16px * 2
//

// MARGINS
.m0 { margin: $default-size / 2; }
.m0-t { margin-top: $default-size / 2; }
.m0-r { margin-right: $default-size / 2; }
.m0-b { margin-bottom: $default-size / 2; }
.m0-l { margin-left: $default-size / 2; }

.m1 { margin: $default-size; }
.m1-t { margin-top: $default-size; }
.m1-r { margin-right: $default-size; }
.m1-b { margin-bottom: $default-size; }
.m1-l { margin-left: $default-size; }

.m2 { margin: $default-size * 1.5; }
.m2-t { margin-top: $default-size * 1.5; }
.m2-r { margin-right: $default-size * 1.5; }
.m2-b { margin-bottom: $default-size * 1.5; }
.m2-l { margin-left: $default-size * 1.5; }

.m3 { margin: $default-size * 2; }
.m3-t { margin-top: $default-size * 2; }
.m3-r { margin-right: $default-size * 2; }
.m3-b { margin-bottom: $default-size * 2; }
.m3-l { margin-left: $default-size * 2; }


//
// PADDINGS
.p0 { padding: $default-size / 2; }
.p0-t { padding-top: $default-size / 2; }
.p0-r { padding-right: $default-size / 2; }
.p0-b { padding-bottom: $default-size / 2; }
.p0-l { padding-left: $default-size / 2; }

.p1 { padding: $default-size; }
.p1-t { padding-top: $default-size; }
.p1-r { padding-right: $default-size; }
.p1-b { padding-bottom: $default-size; }
.p1-l { padding-left: $default-size; }

.p2 { padding: $default-size * 1.5; }
.p2-t { padding-top: $default-size * 1.5; }
.p2-r { padding-right: $default-size * 1.5; }
.p2-b { padding-bottom: $default-size * 1.5; }
.p2-l { padding-left: $default-size * 1.5; }

.p3 { padding: $default-size * 2; }
.p3-t { padding-top: $default-size * 2; }
.p3-r { padding-right: $default-size * 2; }
.p3-b { padding-bottom: $default-size * 2; }
.p3-l { padding-left: $default-size * 2; }


// FONTS UTILS
.bold {
  font-weight: bold;
}
